import { render, staticRenderFns } from "./Serii_dialog.vue?vue&type=template&id=b0423f5a&scoped=true&"
import script from "./Serii_dialog.vue?vue&type=script&lang=js&"
export * from "./Serii_dialog.vue?vue&type=script&lang=js&"
import style0 from "./Serii_dialog.vue?vue&type=style&index=0&id=b0423f5a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0423f5a",
  null
  
)

export default component.exports