<template>
        <el-dialog title="Serii" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :md='8'>
                    <el-form-item label='Serie' prop="Serie">
                        <el-input  class='full-width' v-model='selectedObject.Serie' />
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Numar start' prop="NumarStart">
                        <el-input  class='full-width' v-model='selectedObject.NumarStart'/> 
                    </el-form-item>
                </el-col>
                <el-col :md='8'>
                    <el-form-item label='Implicita'>
                        <el-checkbox v-model='selectedObject.Implicita' true-label='1' false-label='0'> Implicita </el-checkbox>
                    </el-form-item>
                </el-col>            
            
            </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer" >
            <el-button @click="resetForm('my-form')"           > Renunta  </el-button>
			<el-button type="primary" @click="save('my-form')" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Serii_dialog",
        extends: BasePage,
        data () {   
            var checkNumar = (rule, value, callback) => {
                if(!isNaN(value)){
                    if (value < 1) {
                        callback(new Error('Campul trebuie sa contina o valoare mai mare decat 1'));
                    }
                    callback();
                } else {
                    callback(new Error('Campul trebuie sa contina un numar'));
                }
            };
            return {
                showPopup: false,
                mode: 'add',
                selectedObject: { Serie: '' , NumarStart: '' , Implicita: '' },                    
                Info:{ },                                   
                rules: {
                    Serie: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    NumarStart: [ 
                        { validator: checkNumar, trigger:'blur' },
                    ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("serii/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("serii/get_info_for_dialog" );
                            },
            save: async function() {
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("serii/save", { mode: this.mode, object: this.selectedObject } );
                        this.resetForm()
                        this.$emit("save");
                    } else console.log('eroare validare formular!')
                });
            },
            resetForm() {
				this.$refs['my-form'].resetFields()
				this.showPopup = false
			}
        },
        mounted: function() {
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>